import React, {useState, useEffect, Fragment} from 'react';
import './assets/styles/App.css';
import {Route} from 'react-router-dom';
import Home from './pages/home';
import {About} from './pages/about';
import {Products} from './pages/products';
import {Contact} from './pages/contact'; 
import LogIn from './pages/login'; 
import Profile from './pages/profile'; 
import Config from './pages/config';
import Category from './pages/categories';
import Image from './pages/images';

function App() {
    const [categories, setCategories] = useState([]);
    const [changeProducts, setChangeProducts]= useState(true);
    const [isCategories, setIsCategories] = useState(false);
    const [company, setCompany]=useState({});
    const [changeCompany, setChangeCompany]=useState(true);
    const [changeComp, setChangeComp]=useState(true);
    const [windowSize, setWindowSize] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    const [isLogged, setIsLogged] = useState(false);
    const companyInfoPrev= {
        email: "",
        networks: {
            whatsapp: {
                name: "+58 4242196205",
                value:"+584242196205"
            },
            facebook: {
                name:"PLASTICOS3JGT",
                value: "https://www.facebook.com/3jgtCA/"
            },
            instagram: {
                name: "@plasticos3jgt",
                value: "https://www.instagram.com/plasticos3jgt/?hl=es-la"
            },
        },
        map:{
            src:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15692.666124815883!2d-66.8134266!3d10.487535099999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5cd270dec475ae7!2sDistribuidora%20y%20Servicios%203JGT%20C.A!5e0!3m2!1ses!2sve!4v1610204448884!5m2!1ses!2sve"
        },
        displaydata:{
            time:{
                title:7,
                desc:"Años de servicio"
            },
            products:{
                title:"200+",
                desc:"Productos"
            },
            categories:{
                title:3,
                desc:"Líneas de Negocio"
            }
        }
    }
    const [companyInfo, setCompanyInfo]=useState(companyInfoPrev)
    let screenSize = ()=>{
        let currentWindow = {
            height:window.innerHeight, 
            width:window.innerWidth
        }
        setWindowSize(currentWindow);
    }
    useEffect(() => {
        window.addEventListener('resize', screenSize);
        // cleanup this component
        return () => {
            window.removeEventListener('resize',screenSize);
            };
    }, [])
    useEffect(() => {
        const my_url = 'https://server3jgt.herokuapp.com/categories';
        let myProfile = fetch(my_url)
        myProfile.then( async function(response) {
          if(response.ok) {
              let my_json = await response.json();
              setCategories(my_json);
              setIsCategories(true);
          }
        })
    },[changeProducts])
    useEffect(()=>{
        if(!(company.email===undefined)){
            let my_company_info=companyInfo;
            my_company_info.email=company.email
            my_company_info.networks.facebook.name=company.fb_name
            my_company_info.networks.facebook.value=company.fb_link
            my_company_info.networks.instagram.name=company.ig_name
            my_company_info.networks.instagram.value=company.ig_link
            my_company_info.networks.whatsapp.name=company.ws_name
            my_company_info.networks.whatsapp.value=company.ws_number
            my_company_info.map.src=company.maps
            my_company_info.ig_expires_in=company.ig_expires_in
            my_company_info.password=company.email_password
            setCompanyInfo(my_company_info);
            setChangeComp(false);
        }
    },[company, companyInfo])
    useEffect(() => {
        const my_url = 'https://server3jgt.herokuapp.com/companies/own';
        let myProfile = fetch(my_url)
        myProfile.then( async function(response) {
          if(response.ok) {
              let my_json = await response.json();
              setCompany(my_json);
          }
        })
    },[changeCompany])
    return ( 
        <Fragment>
            <Route path='/' exact render={(props) => <Home {...props} isCategories={isCategories} categories={categories} companyInfo={companyInfo} windowSize={windowSize}/>}/>
            <Route path='/about' render={(props) => <About {...props} isCategories={isCategories} categories={categories} companyInfo={companyInfo}/>} />
            <Route path='/products/' exact render={(props) => <Products {...props} isCategories={isCategories} categories={categories} companyInfo={companyInfo} all_products={true} windowSize={windowSize}/>} />
            <Route path='/products/:productId' render={(props) => <Products {...props} isCategories={isCategories} categories={categories} companyInfo={companyInfo} all_products={false} windowSize={windowSize}/>} />
            <Route path='/contactus' render={(props) => <Contact {...props} isCategories={isCategories} categories={categories} companyInfo={companyInfo}/>} />
            <Route path='/admin/login' render={(props) => <LogIn {...props} categories={categories} companyInfo={companyInfo} isLogged={isLogged} setIsLogged={setIsLogged}/>} />
            <Route path='/admin/profile' render={(props) => <Profile {...props} categories={categories} companyInfo={companyInfo} isLogged={isLogged} />} />
            <Route path='/admin/config' render={(props) => <Config {...props} categories={categories} companyInfo={companyInfo} isLogged={isLogged} changeCompany={changeCompany} setChangeCompany={setChangeCompany} changeComp={changeComp}/>} />
            <Route path='/admin/categories' render={(props) => <Category {...props} categories={categories} companyInfo={companyInfo} setChangeProducts={setChangeProducts} changeProducts={changeProducts} isLogged={isLogged} />} />
            <Route path='/admin/images' render={(props) => <Image {...props} />} />
        </Fragment>
    );
}

export default App;
