import React, {useRef, useEffect} from 'react';
import { gsap } from "gsap";
import Image1 from "../assets/images/first_image.png";
import Image2 from "../assets/images/second_image.png";
import Image3 from "../assets/images/third_image.png";
import Image4 from "../assets/images/fourth_image.png";
import background1 from "../assets/images/background2.png";

export const ProductAnimation =(props) => {
    const firstImage = useRef(null);
    const secondImage = useRef(null);
    const thirdImage = useRef(null);
    const fourthImage = useRef(null);
    const interTime=4.5;
    const animEase="expo";
    useEffect(()=>{
        var tl = gsap.timeline({ delay:3, repeat:2, repeatRefresh:true, defaults:{delay:1.2}});

        tl
        .addLabel("firstPart")
        .to(firstImage.current, {duration: interTime, x: 180, ease:animEase},"firstPart")
        .to(secondImage.current, {duration: interTime, y: 180, ease:animEase},"firstPart")
        .to(thirdImage.current, {duration: interTime, x: -180, ease:animEase},"firstPart")
        .to(fourthImage.current, {duration: interTime, y: -180, ease:animEase},"firstPart")
        .addLabel("secondPart")
        .to(firstImage.current, {duration: interTime, y: 180, ease:animEase},"secondPart")
        .to(secondImage.current, {duration: interTime, x:-180, ease:animEase},"secondPart")
        .to(thirdImage.current, {duration: interTime, y:-180, ease:animEase},"secondPart")
        .to(fourthImage.current, {duration: interTime, x: 180, ease:animEase},"secondPart")
        .addLabel("thirdPart")
        .to(firstImage.current, {duration: interTime, x:0, ease:animEase},"thirdPart")
        .to(secondImage.current, {duration: interTime, y: 0, ease:animEase},"thirdPart")
        .to(thirdImage.current, {duration: interTime, x:0, ease:animEase},"thirdPart")
        .to(fourthImage.current, {duration: interTime, y: 0, ease:animEase},"thirdPart")
        .addLabel("fourthPart")
        .to(firstImage.current, {duration: interTime, y: 0, ease:animEase},"fourthPart")
        .to(secondImage.current, {duration: interTime, x:0, ease:animEase},"fourthPart")
        .to(thirdImage.current, {duration: interTime, y: 0, ease:animEase},"fourthPart")
        .to(fourthImage.current, {duration: interTime, x:0, ease:animEase},"fourthPart");
    })
    return (
        <div className="container-rigth-2">
            <img src={background1} alt="backgroundAnimation" className="background"/>
            <div className="fixed-container">
                <div className="AnimationImage" id="firstImage" ref={firstImage}>
                    <a href="/products"><img src={Image1} alt="Conexion" /></a>
                </div>
                <div className="AnimationImage" id="secondImage" ref={secondImage}>
                    <a href="/products"><img src={Image2} alt="Manguera" /></a>
                </div>
                <div className="AnimationImage" id="thirdImage" ref={fourthImage}>
                    <a href="/products"><img src={Image3} alt="Conexion" /></a>
                </div>
                <div className="AnimationImage" id="fourthImage" ref={thirdImage}>
                    <a href="/products"><img src={Image4} alt="Manguera" /></a>
                </div>
            </div>
        </div>
    );
}


/*


*/

/*

        .addLabel("zeroPart")
        .set(fourthImage.current, {x:0,y:0,ease: "none"}, "zeroPart")
        .set(firstImage.current, {x:0,y:0,ease: "none"}, "zeroPart")
        .set(secondImage.current, {x:0,y:0,ease: "none"}, "zeroPart")
        .set(thirdImage.current, {x:0,y:0,ease: "none"}, "zeroPart")
        */