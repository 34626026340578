import React, {useState, useEffect} from 'react';
import { Button, Form, FormGroup, Label, Input,Col, Row } from 'reactstrap';
import ProfileNavbar from '../components/profile-navbar';
import NetworkDisplay from '../components/networkDisplay';
import { useHistory } from "react-router-dom";
//import {isCredential} from '../shared/HttpModule';
import LogoAnimation from '../components/logo-animation';
import Toast from '../components/toast';
import CustomAlert from '../components/custom-alert';

const Config = (props) => {
    const history = useHistory();
    const [email, setEmail] = useState(props.companyInfo.email);
    const [wsName, setWsName] = useState(props.companyInfo.networks.whatsapp.name);
    const [wsValue, setWsValue] = useState(props.companyInfo.networks.whatsapp.value);
    const [password, setPassword] =useState("");
    const [facebookName, setFacebookName] = useState(props.companyInfo.networks.facebook.name);
    const [facebookValue, setFacebookValue] = useState(props.companyInfo.networks.facebook.value);
    const [instagramName, setInstagramName] = useState(props.companyInfo.networks.instagram.name);
    const [instagramValue, setInstagramValue] = useState(props.companyInfo.networks.instagram.value);
    const [mapLink, setMapLink] = useState(props.companyInfo.map.src);
    const [isLogged, setIsLogged] =useState(false);
    const [visible, setVisible] = useState(false);
    const [alertMessage,setAlertMessage]= useState("");
    const [show, setShow] = useState(false);
    const toastToggle = () => setShow(!show);
    const onDismiss = () => setVisible(false);

    const sendForm = (e)=>{
        e.preventDefault();
        let json={};
        if (email===undefined){
            console.log("ERROR")
            return
        }
        if (wsName!==undefined){
            json.ws_name=wsName;
        }
        if (wsValue!==undefined){
            json.ws_number=wsValue;
        }
        if (facebookName!==undefined){
            json.fb_name=facebookName;
        }
        if (facebookValue!==undefined){
            json.fb_link=facebookValue;
        }
        if (instagramName!==undefined){
            json.ig_name=instagramName;
        }
        if (instagramValue!==undefined){
            json.ig_link=instagramValue;
        }
        if (mapLink!==undefined){
            json.maps=mapLink;
        }
        if (password!==undefined && password!==""){
            json.email_password=password;
        }
        json.email=email;

        let my_url='https://server3jgt.herokuapp.com/companies/own';
        let auth= "Bearer " + props.token;
        let method = 'PUT';
        let config ={
            method: method,
            headers:{
              'Authorization': auth,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(json)
        }
        let pareo = fetch(my_url, config)
        pareo.then( async function(response) {
            if(response.ok) {
            console.log("Modificado con exito")
            let new_product = await response.json()
            setAlertMessage("Información Modificada con exito")
            setVisible(true);
            props.setChangeCompany(!props.changeCompany);
            console.log(new_product)
            } else {
            console.log("No Modificado con exito");
            }
        })
        .catch(function(error) {
            console.log('Hubo un problema con la petición Fetch:' + error.message);
            console.log(error)
        })
        
    }
    const refreshIg = ()=>{
        const my_url = 'https://server3jgt.herokuapp.com/ig-auth/refresh';
        let refresh = fetch(my_url)
        refresh.then( async function(response) {
          if(response.ok) {
              setAlertMessage("Token Actualizado con éxito")
              setVisible(true);
          }
        })
    }
    const prettyDate = (time)=>{
        let date=new Date(time*1);
        let response=(date.getUTCDay()+1) + "-" + (date.getUTCMonth()+1)+ "-" +date.getFullYear()

        return response
    }
    useEffect(()=>{

        setEmail(props.companyInfo.email);
        setWsName(props.companyInfo.networks.whatsapp.name);
        setWsValue(props.companyInfo.networks.whatsapp.value);
        setFacebookName(props.companyInfo.networks.facebook.name);
        setFacebookValue(props.companyInfo.networks.facebook.value);
        setInstagramName(props.companyInfo.networks.instagram.name);
        setInstagramValue(props.companyInfo.networks.instagram.value);
        setMapLink(props.companyInfo.map.src);
    },[props.companyInfo,props.changeComp])
      useEffect(() => {
        if(localStorage.token!==undefined){
            let my_url='https://server3jgt.herokuapp.com/test-credential';
            let method='GET';
            let auth= "Bearer " + localStorage.token;
            let config ={
                method: method,
                headers:{
                'Authorization': auth,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                },
            }
            let pareo = fetch(my_url, config)
            pareo.then( async function(response) {
                if(response.ok) {
                    let my_json = await response.json()
                    if(my_json.status) {
                        setIsLogged(true);
                        //setIsLogged(true)
                    } else {
                        history.push("/admin/login");
                        //setIsLogged(false)
                    }
                } else if (response.status===401 ){
                  history.push("/admin/login");
                }
            })
            .catch(function(error) {
            console.log('Hubo un problema con la petición Fetch:' + error.message);
            console.log(error)
            })
        }
    },[history])
  return (
    <div>
        {
            (!(isLogged)) && (
                <LogoAnimation/>
            )
        }
        <ProfileNavbar/>
        <CustomAlert visible={visible} onDismiss={onDismiss} alertMessage={alertMessage}/>
        <Toast show={show} setShow={setShow} toggle={toastToggle}/>
        <div className="container config">
            <h1>Información de la empresa</h1>
            <div className="container">
                <div className="row" >
                    <div className="col-5">
                        <Button className="ig-button">
                            <a style={{color:"white"}} href="https://api.instagram.com/oauth/authorize?client_id=4681164321898291&redirect_uri=https://server3jgt.herokuapp.com/ig-auth&scope=user_profile,user_media&response_type=code&state=is-instagram" target="_blank" rel="noopener noreferrer">
                                Afiliar Instagram
                            </a>
                        </Button>
                    </div>
                    <div className="col-5">
                        <Button className="ig-button" onClick={refreshIg}>Refrescar Instagram</Button>
                    </div>
                    <div className="col-2">
                        <div >Vence el: {prettyDate(props.companyInfo.ig_expires_in)}</div>
                    </div>
                </div>
                <Form onSubmit={sendForm}>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="exampleEmail">Email</Label>
                                <Input type="email" name="email" id="exampleEmail" value={email} onChange={(e)=>setEmail(e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="password">Contraseña de Aplicación de Correo</Label>
                                <Input type="password" name="password" id="password" value={password} onChange={(e)=>setPassword(e.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <NetworkDisplay title1="Etiqueta de WhatsApp" title2="Número de WhatsApp" var1={wsName} var2={wsValue} fun1={setWsName} fun2={setWsValue} for1="whatsappName" for2="whatsappValue"/>
                    <NetworkDisplay title1="Etiqueta de Facebook" title2="Link de Facebook" var1={facebookName} var2={facebookValue} fun1={setFacebookName} fun2={setFacebookValue} for1="facebookName" for2="facebookValue"/>
                    <NetworkDisplay title1="Etiqueta de Instagram" title2="Link de Instagram" var1={instagramName} var2={instagramValue} fun1={setInstagramName} fun2={setInstagramValue} for1="instagramName" for2="instagramValue"/>
                    <FormGroup>
                        <Label for="mapLink">Link de la ubicación en google maps</Label>
                        <Input type="text" name="mapLink" id="mapLink" value={mapLink} onChange={(e)=>setMapLink(e.target.value)}/>
                    </FormGroup>
                    <Button>Submit</Button>
                </Form>
            </div>
        </div>
    </div>
  );
}

export default Config;

/*
                    <FormGroup>
                        <Label for="file">Catálogo</Label>
                        <Input type="file" name="file" id="file" />
                    </FormGroup>
                    */