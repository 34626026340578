import React from 'react';
import '../assets/styles/footer.css';
import FacebookIcon from '../assets/images/facebook-icon.png';
import InstagramIcon from '../assets/images/instagram-icon.png';
//import TwitterIcon from '../assets/images/twitter-icon.png';
import WhatsappIcon from '../assets/images/whatsapp-icon.png';
//import LinkedInIcon from '../assets/images/linkedin-icon.png';

export const CustomFooter =(props) => {
    return (
        <footer className="custom-footer">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="icon-wrapper">
                            <a href={props.companyInfo.networks.facebook.value} target="_blank" rel="noopener noreferrer">
                                <img src={FacebookIcon} alt="Facebook"/>
                            </a>
                            <a href={props.companyInfo.networks.instagram.value} target="_blank" rel="noopener noreferrer">
                                <img src={InstagramIcon} alt="Instagram"/>
                            </a>
                            <a href={"https://api.whatsapp.com/send?phone="+props.companyInfo.networks.whatsapp.value} target="_blank" rel="noopener noreferrer">
                                <img src={WhatsappIcon} alt="WhatsApp"/>
                            </a>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-12">
                        <div className="custom-footer-text">
                            Av. Sanz El Marqués, Calle Pariata con Monsen Sol, Edif. Friuli PB Local C, Urb. El Marqués-Miranda, Caracas, Venezuela
                        </div>
                        <div className="custom-footer-text">
                            © 2021 Plasticos 3JGT C.A. - Rif: J-40439084-7
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

//1206718-20201206-8d98938e ADvent CODE
/*
                            <a href="https://api.whatsapp.com/send?phone=+584242196205" target="_blank" rel="noopener noreferrer">
                                <img src={LinkedInIcon} alt="LinkedIn"/>
                            </a>
                            */