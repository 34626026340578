import React from 'react';
import {CustomNavbar} from '../components/custom-navbar';
import {CustomJumbotron} from '../components/custom-jumbotron';
import WsContact from '../components/ws-contact';
import {CustomFooter} from '../components/custom-footer';
import { DisplayData } from '../components/display-data';
import BottleWater from '../assets/images/bottle_water.png';
import "../assets/styles/about.css";

export const About =(props) => {
    const activeView={
        home:"",
        products:"",
        about:"linear-gradient(180deg, #60D66A 0%, #2FB63B 100%)",
        contact:""
    };
    return (
        <div>
            <CustomJumbotron companyInfo={props.companyInfo}/>
            <CustomNavbar activeView={activeView} categories={props.categories}/>
            <div className="custom-title">
                <div className="container">
                    <div className="row">
                        <div className= "col-12">
                            <h1>Queremos ser parte de tu éxito</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-page">
                <div className="container">
                    <div className="row">
                        <div className= "col-12 col-sm-12 col-md-5 center-self">
                            <img src={BottleWater} alt="bottles"/>
                        </div>
                        <div className="col-12 col-sm-12 col-md-7">
                            <div className="text-wrapper">
                                <div className="parragraph-wrapper">
                                    <h3> Nuestra Mision</h3>
                                    <p>  
                                        Contribuir en el éxito de las organizaciones al proveer productos y servicios de calidad. Enfocándonos en la satisfacción del cliente y el bienestar de nuestro del equipo de trabajo.
                                    </p>
                                </div>
                                <div className="parragraph-wrapper">
                                    <h3>Nuestra Vision</h3>
                                    <p>
                                        Consolidarnos en el mercado nacional agregando valor constantemente a la organización, con un ambiente laboral adecuado para el crecimiento profesional del equipo de trabajo. Aportar al desarrollo de la industria venezolana y contribuir en el bienestar social del país.
                                    </p>
                                </div>
                                <div className="parragraph-wrapper d-sm">
                                    <h3>Nuestro objetivo</h3>
                                    <p>Establecer y consolidar alianzas comerciales con nuestros proveedores, crear sucursales en las principales ciudades del país atendidas con una red de distribución adecuada.</p>
                                </div>
                            </div>
                        </div>
                        <div className="parragraph-wrapper dn-sm">
                            <h3>Nuestro objetivo</h3>
                            <p>Establecer y consolidar alianzas comerciales con nuestros proveedores, crear sucursales en las principales ciudades del país atendidas con una red de distribución adecuada.</p>
                        </div>
                    </div>
                </div>
            </div>              
            <DisplayData companyInfo={props.companyInfo}/>
            <div className="container">
                <div className="row">
                    <div className="col-12 long-text">
                        <p>
                            Distribuidora y Servicios 3JGT, C.A es una empresa dedicada a la fabricación y comercialización de productos derivados 
                            del plástico al mayor y detal, creada por dos (02) jóvenes venezolanos, emprendedores, responsables y comprometidos con 
                            el progreso. Comenzó sus operaciones el 07 de Julio del 2014, en Petare estado Miranda. Inicialmente sus negocios 
                            principales se basaban en la venta de artículos de ferretería, materiales para remodelaciones en drywall y pintura.
                        </p>
                        <hr/>
                        <p>
                            En el año 2015 instaló su primera oficina en La Florida, Caracas Distrito Capital donde sólo se realizaban actividades administrativas, en el 2018 en busca de crecimiento y
                            expansión incursiona en la industria del plástico que posteriormente se convertiría en su 
                            negocio principal. Ese mismo año apertura un galpón ubicado en El Marqués municipio 
                            Sucre donde se instala para poder manejar todas sus operaciones, de manera simultánea abre
                            otra sucursal en Maturín estado Monagas, para atender a los clientes del oriente del país. A 
                            inicios del año 2020 inaugura una nueva oficina en el Marqués municipio sucre, separa las
                            actividades operativas de las administrativas con la intención de ampliar su capacidad de
                            almacenamiento y brindar un mejor servicio a sus clientes en un ambiente confortable.
                        </p>
                        <hr/>
                        <p>
                            Esta empresa desde su inicio se ha caracterizado por ser una organización innovadora
                            que busca adaptarse a las condiciones cambiantes del mercado venezolano, lo que le ha
                            permitido tener un desarrollo constante, cuyo objetivo es convertirse en la mejor opción de
                            la industria del plástico.    
                        </p>
                    </div>
                </div>
            </div>
            <div className="blue-page">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1> Nuestros Valores</h1>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="value-card">
                                        <h3>Perseverancia</h3>
                                        <p>
                                            Nos adaptamos a las situaciones con el fin de continuar con el desarrollo
                                            de nuestra organización.        
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="value-card">
                                        <h3>innovación</h3>
                                        <p>
                                            Siempre nos mantendremos a la vanguardia para brindar a nuestros clientes soluciones innovadoras.       
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="value-card">
                                        <h3>responsabilidad</h3>
                                        <p>
                                            Cumpliremos adecuadamente cada compromiso adquirido.        
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4 offset-sm-2">
                                    <div className="value-card">
                                        <h3>crecimiento</h3>
                                        <p>
                                            Nos mantendremos siempre positivos ante las adversidades y buscaremos soluciones efectivas.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="value-card">
                                        <h3>optimismo</h3>
                                        <p>
                                            Nuestro objetivo permanente siempre será el desarrollo y crecimiento de la organización.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WsContact companyInfo={props.companyInfo}/>
            <CustomFooter companyInfo={props.companyInfo}/>
        </div>
    )
}