import React, {useState, useEffect} from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import BottleEx from '../assets/images/product_without_image.png';

const CategoryForm = (props) => {
    const [name, setName] = useState(props.item.name);
    const [code, setCode] = useState(props.item.code);
    const [position, setPosition] = useState(props.item.position);
    const [description, setDescription] = useState(props.item.description);
    const [categoryImage, setCategoryImage] = useState(BottleEx);
    const [image, setImage] = useState({});

    useEffect(()=>{
      if (!(props.item.image===''||props.item.image===undefined)){
        setCategoryImage("data:image/"+props.item.ext+";base64,"+props.item.image);
      } else if (image instanceof File){
        setCategoryImage(URL.createObjectURL(image))
      }
    },[props.item.image, image, props.item.ext])

    function getFileExtension(filename) {
      return filename.split('.').pop();
    }
    const sendForm = (e)=>{
      e.preventDefault();
      let my_url='https://server3jgt.herokuapp.com/categories';
      let auth= "Bearer " + props.token;
      let json={};

      if (name===undefined){
        console.log("ERROR")
        return
      }
      if (code===undefined){
        console.log("ERROR")
        return
      }
      if (position!==undefined){
        json.position=position;
      }
      json.name=name;
      json.code=code;
      json.description=description;

      //Procesos Dependientes
      if(props.item._id!==undefined){

        let method='PUT';
        my_url=my_url+"/"+props.item._id; 
        if (image instanceof File){
          let image_data = new FormData();
          let image_url="https://server3jgt.herokuapp.com/imageUpload?name=c-" +
                        props.item._id + "&categoryId=" + props.item._id + 
                        "&uploadType=category&ext=."+getFileExtension(image.name);
          image_data.append('imageFile', image);
          let image_pareo = fetch(image_url, {
            method: 'POST', // or 'PUT'
            headers: {
              'Authorization': auth, 
          },
          body: image_data, // data can be `string` or {object}!
          })
          image_pareo.then( async function(response) {
            if(response.ok) {
              let config ={
                method: method,
                headers:{
                  'Authorization': auth,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(json)
                }
                let pareo = fetch(my_url, config)
                pareo.then( async function(response) {
                  if(response.ok) {
                    props.setAlertMessage("Categoría Modificada con exito")
                    props.setVisible(true);
                    props.setActiveItem(-1);
                    props.setChangeProducts(!props.changeProducts);
                    let new_product = await response.json()
                    console.log(new_product)
                  } else {
                    console.log("No Modificado con exito");
                  }
                })
                .catch(function(error) {
                  console.log('Hubo un problema con la petición Fetch:' + error.message);
                })
            } else {
              console.log("imagen Creada sin exito");
            }
          })
          .catch(function(error) {
            console.log('Hubo un problema con la petición Fetch:' + error.message);
          })
        } else{
          console.log("Imagen NO Adjuntada")
          //json.image=props.item.image;
          let config ={
            method: method,
            headers:{
              'Authorization': auth,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(json)
            }
          let pareo = fetch(my_url, config)
          pareo.then( async function(response) {
            if(response.ok) {
              let new_product = await response.json()
              props.setAlertMessage("Categoría Modificada con exito")
              props.setVisible(true);
              props.setActiveItem(-1);
              props.setChangeProducts(!props.changeProducts);
              console.log(new_product)
            } else {
              console.log("No Modificado con exito");
            }
          })
          .catch(function(error) {
            console.log('Hubo un problema con la petición Fetch:' + error.message);
          })
        }
      } else{
        let method='POST';
        json.image="";
        let config ={
          method: method,
          headers:{
            'Authorization': auth,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(json)
          }
        let pareo = fetch(my_url, config)
        pareo.then( async function(response) {
          if(response.ok) {
            props.setAlertMessage("Categoría Creada con exito")
            props.setVisible(true);
            props.setIsShown(false);
            props.setChangeProducts(!props.changeProducts);
            let new_product = await response.json()
            if (image instanceof File){
              //CREAR NUEVA IMAGEN
              let image_data = new FormData();
              let image_url="https://server3jgt.herokuapp.com/imageUpload?name=c-" + new_product._id+
                            "&categoryId=" + new_product._id + 
                            "&uploadType=category&ext=."+getFileExtension(image.name);
              image_data.append('imageFile', image);
              let image_pareo = fetch(image_url, {
                method: 'POST', // or 'PUT'
                headers: {
                  'Authorization': auth, 
              },
              body: image_data, // data can be `string` or {object}!
              })
              image_pareo.then( async function(response) {
                if(response.ok) {
                  props.setAlertMessage("Imagen Adjuntada con exito")
                  props.setVisible(true);
                  props.setChangeProducts(!props.changeProducts);
                } else {
                  console.log("imagen Creada sin exito");
                }
              })
              .catch(function(error) {
                console.log('Hubo un problema con la petición Fetch:' + error.message);
              })
              //FIN CREACION
            } else{
              console.log("Imagen NO Adjuntada")
            }
          } else {
            console.log("No Modificado con exito");
          }
        })
        .catch(function(error) {
          console.log('Hubo un problema con la petición Fetch:' + error.message);
        })
      }
      console.log("Fin de peticion")
    }
  return (
    <Form onSubmit={sendForm}>
      <FormGroup>
        <Label for="name">Nombre</Label>
        <Input type="text" name="name" id="name" value={name} onChange={(e)=>setName(e.target.value)} />
      </FormGroup>
      <Row form>
        <Col md={8}>
          <FormGroup>
            <Label for="code">Código</Label>
            <Input type="text" name="code" id="code" value={code} onChange={(e)=>setCode(e.target.value)}/>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="position">Posición</Label>
            <Input type="text" name="position" id="position" value={position} onChange={(e)=>setPosition(e.target.value)}/>
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="description">Descripción</Label>
        <Input type="text" name="description" id="description" value={description} onChange={(e)=>setDescription(e.target.value)}/>
      </FormGroup>
      <Row form>
        <Col md={8}>
            <FormGroup>
                <Label for="picture">Imagen</Label>
                <Input type="file" name="file" id="picture" onChange={(event) => setImage(event.target.files[0])}/>
            </FormGroup>
        </Col>
        <Col md={4}>
            <img width="120px" src={categoryImage} alt={"Category"}/>
        </Col>
      </Row>
      <Button>Enviar</Button>
    </Form>
  );
}

export default CategoryForm;