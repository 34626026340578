import React, {useState, useEffect} from 'react';
import {
  Card, CardImg, CardText, CardTitle
} from 'reactstrap';
import BottleEx from '../assets/images/product_without_image.png';

const CardType3= (props) => {
  const [productImage, setProductImage] = useState(BottleEx);
  const [activeCardSize, setActiveCardSize] =useState("100%");
  useEffect(()=>{
    if (!(props.item.image==='')){
      setProductImage("data:image/"+props.item.ext+";base64,"+props.item.image);
    }
  },[props.item])

  useEffect(()=>{
    if(props.isActive){
      if((props.windowSize.width<992)&&(props.windowSize.width>575)){
        setActiveCardSize("50%");
      } else{
        setActiveCardSize("100%")
      }
    }
},[props.windowSize, props.isActive])
  return (
        <a href="#products-init" className={"custom-card-3"} style={ (props.isActive)? {width:activeCardSize} : {width:""}} onClick={()=>props.setIsShown(true,props.item) }>
          <Card>
              <div className={"card-img-wrapper"}>
                <CardImg top width="100%" src={productImage} alt="Card cap" />
              </div>
              <hr className={"hr_blue"}/>
              <CardTitle className={"card-3-title"}>{props.item.name}</CardTitle>
              <CardText className={"card-3-desc"}>{props.item.description}</CardText>
              <hr className={"hr_orange"}/>
          </Card>
        </a>
  );
};

export default CardType3;

//src={productImage}
//              <CardText className={"card-3-desc"}>{props.item.size}</CardText>