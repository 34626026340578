import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input} from 'reactstrap';
import "../assets/styles/login.css";
import { useHistory } from "react-router-dom";

const LogIn = (props) => {
    const history = useHistory();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const SendLogin = (event) =>{
        event.preventDefault();
        let my_url='https://server3jgt.herokuapp.com/users/login';
        let method='POST';
        let json={};
        json.username=username;
        json.password=password;
        let config ={
            method: method,
            headers:{
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(json)
        }
        
        let pareo = fetch(my_url, config)
        pareo.then( async function(response) {
          if(response.ok) {
            let my_user = await response.json()
            localStorage.setItem('token',my_user.token);
            //props.setIsLogged(true);
            history.push("/admin/profile");
            //window.location="/admin/profile/"; 
          } else {
            console.log("No Modificado con exito");
          }
        })
        .catch(function(error) {
          console.log('Hubo un problema con la petición Fetch:' + error.message);
          console.log(error)
        })
    }
    return (
        <div className="loginPage">
            <div className="custom-container">
                <div className="white-container">
                    <h4>Inicio de sesión</h4>
                    <Form onSubmit={SendLogin}>
                        <FormGroup>
                            <Label for="adminUser">Usuario</Label>
                            <Input type="user" name="user" id="adminUser" placeholder="admin" value={username} 
                                onChange={(event) => {
                                    setUsername(event.target.value);
                                    }
                                }/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="adminPassword">Contraseña</Label>
                            <Input type="password" name="password" id="adminPassword" placeholder="********" value={password} 
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                    }
                                }/>
                        </FormGroup>
                        <Button color="primary">Entrar</Button>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default LogIn;