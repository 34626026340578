import React from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';

const NetworkDisplay = (props) => {

  return (
        <Row form>
            <Col md={6}>
            <FormGroup>
                <Label for={props.for1}>{props.title1}</Label>
                <Input type="text" name={props.for1} id={props.for1} value={props.var1} onChange={(e)=>props.fun1(e.target.value)}/>
            </FormGroup>
            </Col>
            <Col md={6}>
            <FormGroup>
                <Label for={props.for2}>{props.title2}</Label>
                <Input type="text" name={props.for2} id={props.for2} value={props.var2} onChange={(e)=>props.fun2(e.target.value)}/>
            </FormGroup>
            </Col>
        </Row>
  );
}

export default NetworkDisplay;