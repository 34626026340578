import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem
} from 'reactstrap';
import "../assets/styles/profile-navbar.css";
import { useHistory } from "react-router-dom";

const ProfileNavbar = (props) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const closeSession= ()=>{
    localStorage.removeItem('token')
    history.push("/admin/login/");
  }
  return (
    <div>
      <Navbar color="#4F7DDE" light expand="md" className="profile-navbar">
        <NavbarBrand href="/" className="white">3JGT</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavLink href="/admin/profile/" className="white">Productos</NavLink>
            <NavLink href="/admin/categories/" className="white">Categorías</NavLink>
          </Nav>
          <UncontrolledDropdown  className="nav-item">
            <DropdownToggle nav caret className="white">
              Config
            </DropdownToggle>
            <DropdownMenu >
              <DropdownItem >
                <NavLink href="/admin/config/">Empresa</NavLink>
              </DropdownItem>
              <DropdownItem >
                <NavLink onClick={closeSession}>Cerrar Sesión</NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default ProfileNavbar;