import React, {useState, useEffect} from 'react';
import InstagramPost from './instagram-post';

export const InstagramComponent =(props) => {
    const [ posts, setPosts] =useState([]);

    useEffect(() => {
        const my_url = 'https://server3jgt.herokuapp.com/posts';
        let myProfile = fetch(my_url)
        myProfile.then( async function(response) {
          if(response.ok) {
              let my_json = await response.json();
              setPosts(my_json.data);
          }
        })
    },[])
    return (
    <div className="page page--white">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h2>Síguenos en nuestras redes sociales</h2>
                    <div className="row">
                        {
                            (!(posts=== undefined)) && posts.map((item, index) => {
                                return (
                                    <InstagramPost permalink={item.permalink} key={"ig-" + index} caption={item.caption} src={item.media_url} alt={"Post de Instagram "+index}/>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}