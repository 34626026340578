import React, {useEffect, useState} /*, {useState, useEffect, useRef} */ from 'react';

const Image = (prop) => {
    const [images, setImages] = useState([]);
    useEffect(() => {
        const my_url = 'https://server3jgt.herokuapp.com/images/';
        let myProfile = fetch(my_url)
        myProfile.then( async function(response) {
            if(response.ok) {
                let my_json = await response.json()
                setImages(my_json);
            }
        })
    },[])
    return (
        <div className="profile-page">
            <div>epa</div>
            {
                images.map((item, index) => {
                    return (
                        <img src={"data:image/png;base64,"+item.base} alt={"prueba"+index} key={"img"+item.name+index}/>
                    )
                })
            }
        </div>
    );
}

export default Image;