import React, { useEffect } from 'react';
import { Alert } from 'reactstrap';
import '../assets/styles/custom-alert.css';

const CustomAlert= (props) => {
  //const [visible, setVisible] = useState(true);

  //const onDismiss = () => setVisible(false);
  useEffect(()=>{
    if(props.visible){
      setTimeout(props.onDismiss,4500);
    }
  },[props.visible, props.onDismiss])
  return (
    <Alert color="success" isOpen={props.visible} toggle={props.onDismiss} className="custom-alert">
      {props.alertMessage}
    </Alert>
  );
}

export default CustomAlert;