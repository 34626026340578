import React, {useEffect, useState} /*, {useState, useEffect, useRef} */ from 'react';
import "../assets/styles/profile.css";
import ProductForm from '../components/product-form';
import ProfileNavbar from '../components/profile-navbar';
import { useHistory } from "react-router-dom";
import Toast from '../components/toast';
import CustomAlert from '../components/custom-alert';
import LogoAnimation from '../components/logo-animation';
//import {useCredential} from '../shared/HttpModule';
//import { Button, Form, FormGroup, Label, Input} from 'reactstrap';
//import LogoAnimation from '../components/logo-animation';
//import { gsap } from "gsap";

const Profile = (prop) => {
  const history = useHistory();
  const [activeItem, setActiveItem] = useState(-1);
  const [products, setProducts] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const token=localStorage.token;
  //const toggle = (show,setShow) => setShow(!show);
  const [show, setShow] = useState(false);
  //const [isVisible, setIsVisible] = useState(false);
  //const isLogged = useCredential();
  const toastToggle = () => setShow(!show);

  const [visible, setVisible] = useState(true);
  const [alertMessage,setAlertMessage]= useState("Inicio de Sesión satisfactorio");
  const onDismiss = () => setVisible(false);
  const [isLogged, setIsLogged] =useState(false);

  const [changeProducts, setChangeProducts]= useState(true);
  const deleteProduct = (id)=>{
    console.log(id);
    let my_url='https://server3jgt.herokuapp.com/products/' + id;
    let method='DELETE';
    let auth= "Bearer " + token;
    let config ={
      method: method,
      headers:{
        'Authorization': auth,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
    //console.log(auth);
    let pareo = fetch(my_url, config)
    pareo.then( async function(response) {
      if(response.ok) {
        setAlertMessage("Eliminado con exito")
        setVisible(true);
        setChangeProducts(!changeProducts);
      } else {
        console.log("No Modificado con exito");
      }
    })
    .catch(function(error) {
      console.log('Hubo un problema con la petición Fetch:' + error.message);
      console.log(error)
    })
  }
  
  useEffect(() => {
    const my_url = 'https://server3jgt.herokuapp.com/products/';
    let myProfile = fetch(my_url)
    myProfile.then( async function(response) {
        if(response.ok) {
            let my_json = await response.json()
            setProducts(my_json);
        }
    })
  },[changeProducts])

    useEffect(()=>{
      if(activeItem!==-1){
        setIsShown(false);
      }
    },[activeItem])
    useEffect(()=>{
      if(isShown){
        setActiveItem(-1);
      }
    },[isShown])

    useEffect(() => {
      if(localStorage.token!==undefined){
          let my_url='https://server3jgt.herokuapp.com/test-credential';
          let method='GET';
          let auth= "Bearer " + localStorage.token;
          let config ={
              method: method,
              headers:{
              'Authorization': auth,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
              },
          }
          let pareo = fetch(my_url, config)
          pareo.then( async function(response) {
              if(response.ok) {
                  let my_json = await response.json()
                  if(my_json.status) {
                      setIsLogged(true);
                      //setIsLogged(true)
                  } else {
                      history.push("/admin/login");
                      //setIsLogged(false)
                  }
              } else if (response.status===401 ){
                history.push("/admin/login");
              }
          })
          .catch(function(error) {
          console.log('Hubo un problema con la petición Fetch:' + error.message);
          })
      }
  },[history])

    return (
        <div className="profile-page" >
            {
                (!(isLogged)) && (
                    <LogoAnimation/>
                )
            }
            <ProfileNavbar/>
            <CustomAlert visible={visible} onDismiss={onDismiss} alertMessage={alertMessage}/>
            <Toast show={show} setShow={setShow} toggle={toastToggle}/>
            <div className="container">
                <div className="row">
                    <div className="col-10">
                        <h1 className="title" >Productos </h1>
                    </div>
                    <div className="col-2">
                        <button className="button" onClick={()=>{setIsShown(!isShown)}}>{isShown? "-" : "+"}</button>
                    </div>
                </div>
                {
                  isShown && (
                    <div className="row">
                        <div className="col-12">
                          <ProductForm title="Agregar Nuevo Producto" categories={prop.categories} item={{featured:false}} token={token} setAlertMessage={setAlertMessage} setVisible={setVisible} setChangeProducts={setChangeProducts} changeProducts={changeProducts} setIsShown={setIsShown}/>
                        </div>
                    </div>
                  )
                }
                {
                    products.map((item, index) => {
                        return (
                          <div className="row single-product" key={index}>
                            <div className="col-4">
                              {item.name}
                            </div>
                            <div className="col-4">
                              Código: {item.code} 
                            </div>
                            <div className="col-2">
                            </div>
                            <div className="col-2">
                              <input type="checkbox" id={"cbox"+index} className="profile-checkbox" value="first_checkbox" checked={item.featured} readOnly/>
                              <button className="green-button" onClick={()=>setActiveItem(index)}>M</button>
                              <button className="red-button" onClick={()=>deleteProduct(item._id)}>X</button>
                            </div>
                            { (index===activeItem) && (
                              <div className="col-12">
                                  <ProductForm title="Modificar Producto" categories={prop.categories} item={item} token={token} setAlertMessage={setAlertMessage} setVisible={setVisible} setChangeProducts={setChangeProducts} changeProducts={changeProducts} setActiveItem={setActiveItem}/>
                              </div>
                            )}
                        </div>                            
                        )
                    })
                }
            </div>
        </div>
    );
}

export default Profile;

/*
                            <div className="col-2">
                              imagen
                            </div>
*/