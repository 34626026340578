import React, {useState, useEffect} from 'react';
import { Card, CardTitle, CardText, CardImg, CardImgOverlay } from 'reactstrap';
import BottleEx from '../assets/images/Imagen_no_disponible.png';
import '../assets/styles/custom-card.css';

const CardType1 = (prop) => {
  const [productImage, setProductImage] = useState(BottleEx);
  useEffect(()=>{
    if (!(prop.image==='')){
      setProductImage("data:image/"+prop.ext+";base64,"+prop.image);
    }
  },[prop.image, prop.ext])
  return (
    <div className="col-lg-3 col-md-6">
      <a href={prop.aroute}>
        <div className="custom-card">
          <Card inverse>
              <CardImg className="card-img" src={productImage} alt="Card image cap" />
              <CardImgOverlay>
              <CardTitle className="card-title">{prop.title}</CardTitle>
              <hr/>
              <CardText className="card-text">{prop.text}</CardText>
              </CardImgOverlay>
          </Card>
        </div>
      </a>
    </div>
  );
};

export default CardType1;