import React, {useState, useEffect} from 'react';
import { CustomInput, Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import BottleEx from '../assets/images/product_without_image.png';
//import { isBodyOverflowing } from 'reactstrap/lib/utils';

const ProductForm = (props) => {
    const [name, setName] = useState(props.item.name);
    const [code, setCode] = useState(props.item.code);
    const [pfv, setPfv] = useState(props.item.pfv);
    const [position, setPosition] = useState(props.item.position);
    const [size, setSize] = useState(props.item.size);
    const [unities, setUnities] = useState(props.item.unities);
    const [description, setDescription] = useState(props.item.description);
    const [featured, setFeatured] = useState(props.item.featured);
    const [category, setCategory] =useState((props.item.category===undefined)? props.categories[0]._id:props.item.category)
    const [image, setImage] = useState({});
    const [productImage, setProductImage] = useState(BottleEx);

    useEffect(()=>{
      if (image instanceof File){
        setProductImage(URL.createObjectURL(image))
      } else if (!(props.item.image===''||props.item.image===undefined)){
        setProductImage("data:image/"+props.item.ext+";base64,"+props.item.image);
        //setProductImage(props.item.image);
      } 
    },[props.item, image])

    function getFileExtension(filename) {
      return filename.split('.').pop();
    }
    const sendForm = (e)=>{
      
      //Proceso Comun
      e.preventDefault();
      let my_url='https://server3jgt.herokuapp.com/';
      let auth= "Bearer " + props.token;
      let json={};
      if (name!==undefined){
        json.name=name;
      }
      if (code===undefined){
        console.log("ERROR")
        return
      }
      if (pfv!==undefined){
        json.pfv=pfv;
      }
      if (size!==undefined){
        json.size=size;
      }
      if (unities!==undefined){
        json.unities=unities;
      }
      if (description!==undefined){
        json.description=description;
      }
      if (position!==undefined){
        json.position=position;
      }
      json.code=code;
      json.featured=featured;
      
      //Procesos Dependientes
      if(props.item._id!==undefined){
        json.category=category;
        let method='PUT';
        my_url=my_url+"products/"+props.item._id; 
        if (image instanceof File){
          let image_data = new FormData();
          let image_url="https://server3jgt.herokuapp.com/imageUpload?name=p-" +
                        props.item._id + "&productId=" + props.item._id + 
                        "&uploadType=image&ext=."+getFileExtension(image.name);
          image_data.append('imageFile', image);
          let image_pareo = fetch(image_url, {
            method: 'POST', // or 'PUT'
            headers: {
              'Authorization': auth, 
          },
          body: image_data, // data can be `string` or {object}!
          })
          image_pareo.then( async function(response) {
            if(response.ok) {
              let new_image = await response.json()
              json.image=new_image.base;
              let config ={
                method: method,
                headers:{
                  'Authorization': auth,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(json)
                }
                let pareo = fetch(my_url, config)
                pareo.then( async function(response) {
                  if(response.ok) {
                    props.setAlertMessage("Producto Modificado con exito")
                    props.setVisible(true);
                    props.setActiveItem(-1);
                    props.setChangeProducts(!props.changeProducts);
                    let new_product = await response.json()
                    console.log(new_product)
                  } else {
                    console.log("No Modificado con exito");
                  }
                })
                .catch(function(error) {
                  console.log('Hubo un problema con la petición Fetch:' + error.message);
                })
            } else {
              console.log("imagen Creada sin exito");
            }
          })
          .catch(function(error) {
            console.log('Hubo un problema con la petición Fetch:' + error.message);
          })
        } else{
          console.log("Imagen NO Adjuntada")
          //json.image=props.item.image;
          let config ={
            method: method,
            headers:{
              'Authorization': auth,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(json)
            }
          let pareo = fetch(my_url, config)
          pareo.then( async function(response) {
            if(response.ok) {
              let new_product = await response.json()
              props.setAlertMessage("Producto Modificado con exito")
              props.setVisible(true);
              props.setActiveItem(-1);
              props.setChangeProducts(!props.changeProducts);
              console.log(new_product)
            } else {
              console.log("No Modificado con exito");
            }
          })
          .catch(function(error) {
            console.log('Hubo un problema con la petición Fetch:' + error.message);
          })
        }
      } else{
        let method='POST';
        my_url=my_url+"categories/"+category + "/products";
        json.image="";
        let config ={
          method: method,
          headers:{
            'Authorization': auth,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(json)
          }
        let pareo = fetch(my_url, config)
        pareo.then( async function(response) {
          if(response.ok) {
            props.setAlertMessage("Producto Creado con exito")
            props.setVisible(true);
            props.setIsShown(false);
            props.setChangeProducts(!props.changeProducts);
            let new_product = await response.json()
            if (image instanceof File){
              //CREAR NUEVA IMAGEN
              let image_data = new FormData();
              let image_url="https://server3jgt.herokuapp.com/imageUpload?name=p-" + new_product._id+
                            "&productId=" + new_product._id + 
                            "&uploadType=image&ext=."+getFileExtension(image.name);
              image_data.append('imageFile', image);
              let image_pareo = fetch(image_url, {
                method: 'POST', // or 'PUT'
                headers: {
                  'Authorization': auth, 
              },
              body: image_data, // data can be `string` or {object}!
              })
              image_pareo.then( async function(response) {
                if(response.ok) {
                  props.setAlertMessage("Imagen Adjuntada con exito")
                  props.setVisible(true);
                  props.setChangeProducts(!props.changeProducts);
                } else {
                  console.log("imagen Creada sin exito");
                }
              })
              .catch(function(error) {
                console.log('Hubo un problema con la petición Fetch:' + error.message);
              })
              //FIN CREACION
            } else{
              console.log("Imagen NO Adjuntada")
            }
          } else {
            console.log("No Modificado con exito");
          }
        })
        .catch(function(error) {
          console.log('Hubo un problema con la petición Fetch:' + error.message);
        })
      }
      console.log("Fin de peticion")
    }
  return (
    <div className="custom-form">
      <Form onSubmit={sendForm}>
      <Row form>
          <Col md={9}>
            <h2>{props.title}</h2>
          </Col>
          <Col md={3}>
              <FormGroup check>
                  <Label check>
                  <CustomInput type="switch" id="featured" name="featured" label="Promocionado" checked={featured} onChange={(e)=>setFeatured(!featured)}/>
                  </Label>
              </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="name">Nombre</Label>
          <Input type="text" name="name" id="name" value={name} onChange={(e)=>setName(e.target.value)} />
        </FormGroup>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="categorySelect">Categorías</Label>
              <Input type="select" name="select" id="categorySelect" value={category} onChange={(e)=>setCategory(e.target.value)}>
                  {
                      props.categories.map((item, index) => {
                          return (
                              <option value={item._id} key={"customSelect"+index}>
                                  {item.name}
                              </option>
                          )
                      })
                  }
              </Input>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="position">Posición</Label>
              <Input type="text" name="position" id="position" value={position} onChange={(e)=>setPosition(e.target.value)}/>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="code">Código</Label>
              <Input type="text" name="code" id="code" value={code} onChange={(e)=>setCode(e.target.value)}/>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="pfv">PFV</Label>
              <Input type="text" name="pfv" id="pfv" value={pfv} onChange={(e)=>setPfv(e.target.value)}/>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="unities">Cantidad por bulto</Label>
              <Input type="text" name="unities" id="unities" value={unities} onChange={(e)=>setUnities(e.target.value)}/>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="size">Tamaño</Label>
              <Input type="text" name="size" id="size" value={size} onChange={(e)=>setSize(e.target.value)}/>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="description">Descripción</Label>
          <Input type="text" name="description" id="description" value={description} onChange={(e)=>setDescription(e.target.value)}/>
        </FormGroup>
        <Row form>
          <Col md={8}>
              <FormGroup>
                  <Label for="picture">Imagen</Label>
                  <Input type="file" name="file" id="picture" onChange={(event) => setImage(event.target.files[0])}/>
              </FormGroup>
          </Col>
          <Col md={4}>
            <img width="120px" src={productImage} alt={"Producto"}/>
          </Col>
        </Row>
        <Button>Enviar</Button>
      </Form>
    </div>
  );
}

export default ProductForm;

/*
                <FormText >
                Adjunte una imagen para el producto.
                </FormText>
                */

//TENGO QUE HACER LA LOGICA PARA CREAR NUEVA IMAGEN, PRIMERO PRODUCTO, LUEGO IMAGEN
//REVISAR PORQUE NO ENTRA EN SIN IMAGEN