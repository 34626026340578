import React from 'react';
import '../assets/styles/ws-contact.css';

const WsContact= (props) => {
  return (
    <div className="banner">
        <a href={"https://api.whatsapp.com/send?phone="+props.companyInfo.networks.whatsapp.value} target="_blank" rel="noopener noreferrer">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="text-wrapper">
                        <h2>CONTÁCTANOS</h2>
                        <div className="ws-icon">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/240px-WhatsApp.svg.png" alt="WhatsApp"/>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </a>
    </div>
  );
};

export default WsContact;