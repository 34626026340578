import React, {useState, useEffect, useRef} from 'react';
import {CustomNavbar} from '../components/custom-navbar';
import {CustomJumbotron} from '../components/custom-jumbotron';
import {CustomFooter} from '../components/custom-footer';
import '../assets/styles/home.css';
import { DisplayData } from '../components/display-data';
import WsContact from '../components/ws-contact';
import Animation from '../assets/images/Animacion_3D.webm';
import Border from '../assets/images/Borde_process.png';
import CategoryCarousel from '../components/category-carousel';
import PromoProductsCarousel from '../components/promo-products-carousel';
import {InstagramComponent} from '../components/instagram-component'
import LogoAnimation from '../components/logo-animation';
import AnimationImage from '../assets/images/Animacion_Fondo.png';
import {MainAnimation2} from '../components/main-animation2';
//import ClientsComponent from '../components/clients-component';

function Home(props) {
    const activeView={
        home:"linear-gradient(180deg, #60D66A 0%, #2FB63B 100%)",
        products:"",
        about:"",
        contact:""
    }
    //const isFirefox = typeof myVideo.loop == 'boolean';
    const [promoProducts, setPromoProducts] = useState([]);
    //const [loading, setLoading] = useState(true);
    const [isPromoProducts, setIsPromoProducts] = useState(false);
    const [isPhone, setIsPhone] = useState(false);
    const backgroundVideo = useRef(null);
    const blueContainer = useRef(null);
    const backgroundAnimation = useRef(null);
    const [topProperty, setTopProperty] = useState("");
    //const [isShown, setIsShown] = useState(false);
    const [leftContainerHeight, setLeftContainerHeight] = useState("");
    const [forceDisplay, setForceDisplay] = useState(false);
    //const [videoHeight, setVideoHeight] = useState(0);
    useEffect(()=>{
        if(props.windowSize.width<576){
            setIsPhone(true);
            setTopProperty((blueContainer.current.clientHeight +61-32/2) + "px")
            setLeftContainerHeight("");
        } else{
            setIsPhone(false);
            //setLeftContainerHeight(backgroundAnimation.current.clientHeight+"px");
        }
    },[props.windowSize, isPhone])

    useEffect(() => {
        const my_url = 'https://server3jgt.herokuapp.com/products?featured=true';
        let myProfile = fetch(my_url)
        myProfile.then( async function(response) {
          if(response.ok) {
              let my_json = await response.json();
              setPromoProducts(my_json)
              setIsPromoProducts(true);
          }
        })
    },[]);
    /*
    useEffect(() => {
        if (!loading){
            if (isShown){
                const timer = setTimeout(() => {
                    if(backgroundVideo.current.currentTime>0){
                        setIsShown(false)
                    } else{
                        setIsShown(true)
                    }
                  }, 3000);
                  return () => clearTimeout(timer);
            } else{
                const timer = setTimeout(() => {
                    if(backgroundVideo.current.currentTime>0){
                        setIsShown(false)
                    } else{
                        setIsShown(true)
                    }
                  }, 400);
                  return () => clearTimeout(timer);
            }
        }
      }, [loading, isShown]);
    useEffect(() => {
        window.addEventListener('load', initAnimation());
        // cleanup this component
        return () => {
            window.removeEventListener('load', initAnimation());
        };
    }, []);*/

    useEffect(()=>{
        const timer = setTimeout(()=>{
            setForceDisplay(true);
        },6000);
        /*
        const timer = setTimeout(()=>{
            setForceDisplay(true);
        },6500);*/
        return () => clearTimeout(timer);
    },[])
    /*
    const initAnimation= () =>{
        backgroundVideo.current.addEventListener('timeupdate',setLoading(false));
        // cleanup this component
        return () => {
            backgroundVideo.current.removeEventListener('timeupdate', setLoading(false));
        };
    }*/

    return (
        <div>
            {
                (!((false && props.isCategories && isPromoProducts)|| forceDisplay)) && (
                    <LogoAnimation/>
                )
            }
            <div style={ (( props.isCategories && isPromoProducts)|| forceDisplay)? {opacity:1} : {opacity:0}}>
                <CustomJumbotron companyInfo={props.companyInfo}/>
                <CustomNavbar activeView={activeView} categories={props.categories} />
                <MainAnimation2 backgroundAnimation={backgroundAnimation}
                            leftContainerHeight={leftContainerHeight}
                            blueContainer={blueContainer}
                            Animation={Animation}
                            backgroundVideo={backgroundVideo}
                            AnimationImage={AnimationImage}
                            isPhone={isPhone}
                            topProperty={topProperty}/>
                <div className="page">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2>¿Cómo es el proceso?</h2>
                                <div className="background-process"> 
                                    <img className="img-1" src={Border} alt="process"/>
                                    <img className="img-2" src={Border} alt="process"/>
                                    <img className="img-3" src={Border} alt="process"/>
                                    <img className="img-4" src={Border} alt="process"/>
                                    <img className="img-5" src={Border} alt="process"/>
                                    <div className="process-steps-wrapper">
                                        <div className="process-step-wrapper">
                                            <div>1</div>
                                            <h3>Revisa el catÁlogo</h3>
                                        </div>
                                        <div className="process-step-wrapper">
                                            <div>2</div>
                                            <h3>Escoge tu producto</h3>
                                        </div>
                                        <div className="process-step-wrapper">
                                            <div>3</div>
                                            <h3 className="less">Contáctanos por WhatsApp</h3>
                                        </div>
                                        <div className="process-step-wrapper">
                                            <div>4</div>
                                            <h3>Elige forma de pago</h3>
                                        </div>
                                        <div className="process-step-wrapper">
                                            <div>5</div>
                                            <h3>Retira tu orden</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DisplayData companyInfo={props.companyInfo}/>
                <CategoryCarousel categories={props.categories} windowSize={props.windowSize}/>
                {
                    (promoProducts!==undefined && promoProducts.length !== 0) && (
                        <PromoProductsCarousel promoProducts={promoProducts} companyInfo={props.companyInfo} windowSize={props.windowSize}/>
                    )
                }
                <InstagramComponent/>
                <WsContact companyInfo={props.companyInfo}/>
                <CustomFooter companyInfo={props.companyInfo}/>
            </div>
        </div>
    );
}

export default Home;

//                 <ClientsComponent/>

/*
                <div className="animation" ref={backgroundAnimation}>
                    <div className="animation-bg">
                        <div className="animation-z-index" style={{height:leftContainerHeight}}>
                            <div className="container-left" ref={blueContainer}>
                                <h1> QUEREMOS SER PARTE DE TU ÉXITO</h1>
                                <p> Fabricamos y comercializamos productos derivados del plástico necesarios en diversos sectores productivos a nivel nacional.</p>
                            </div>
                        </div>
                        <video autoPlay loop src={Animation} muted width="100%" ref={backgroundVideo}></video>
                        {
                            (isShown) && (
                                <img src={AnimationImage} alt="animation-background" width="100%"/>
                            )
                        }
                    </div>
                    <a className="catalog-button" href="/products"><button style={isPhone? {top:topProperty} : {top:""}}> Ver Productos</button></a> 
                </div>

                                <MainAnimation backgroundAnimation={backgroundAnimation}
                            leftContainerHeight={leftContainerHeight}
                            blueContainer={blueContainer}
                            Animation={Animation}
                            backgroundVideo={backgroundVideo}
                            isShown={isShown}
                            AnimationImage={AnimationImage}
                            isPhone={isPhone}
                            topProperty={topProperty}/>

                                        {
                (!((!loading && props.isCategories && isPromoProducts)|| forceDisplay)) && (
                    <LogoAnimation/>
                )
            }            {
                (!((!loading && props.isCategories && isPromoProducts)|| forceDisplay)) && (
                    <LogoAnimation/>
                )
            }
*/

//<div style={ ((!loading && props.isCategories && isPromoProducts)|| forceDisplay)? {opacity:1} : {opacity:0}}>