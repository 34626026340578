import React, {useEffect, useState} /*, {useState, useEffect, useRef} */ from 'react';
import "../assets/styles/profile.css";
import CategoryForm from '../components/category-form';
import ProfileNavbar from '../components/profile-navbar';
import { useHistory } from "react-router-dom";
//import {isCredential} from '../shared/HttpModule';
import LogoAnimation from '../components/logo-animation';
import Toast from '../components/toast';
import CustomAlert from '../components/custom-alert';

const Category = (prop) => {
  const token=localStorage.token;
  const history = useHistory();
  const [activeItem, setActiveItem] = useState(-1);
  const [isShown, setIsShown] = useState(false);
  const [isLogged, setIsLogged] =useState(false);
  const [show, setShow] = useState(false);
  const toastToggle = () => setShow(!show);
  const [visible, setVisible] = useState(false);
  const [alertMessage,setAlertMessage]= useState("");
  const onDismiss = () => setVisible(false);
  //Pendiente logica de change categories


  const deleteCategory = (id)=>{
    let my_url='https://server3jgt.herokuapp.com/categories/' + id;
    let method='DELETE';
    let auth= "Bearer " + token;
    let config ={
      method: method,
      headers:{
        'Authorization': auth,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
    //console.log(auth);
    let pareo = fetch(my_url, config)
    pareo.then( async function(response) {
      if(response.ok) {
        setAlertMessage("Categoría Eliminada con exito")
        setVisible(true);
        setActiveItem(-1);
        prop.setChangeProducts(!prop.changeProducts);
      } else {
        console.log("No Modificado con exito");
      }
    })
    .catch(function(error) {
      console.log('Hubo un problema con la petición Fetch:' + error.message);
    })
  }

    useEffect(()=>{
      if(activeItem!==-1){
        setIsShown(false);
      }
    },[activeItem])
    useEffect(()=>{
      if(isShown){
        setActiveItem(-1);
      }
    },[isShown])
    useEffect(() => {
      if(localStorage.token!==undefined){
          let my_url='https://server3jgt.herokuapp.com/test-credential';
          let method='GET';
          let auth= "Bearer " + localStorage.token;
          let config ={
              method: method,
              headers:{
              'Authorization': auth,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
              },
          }
          let pareo = fetch(my_url, config)
          pareo.then( async function(response) {
              if(response.ok) {
                  let my_json = await response.json()
                  if(my_json.status) {
                      setIsLogged(true);
                      //setIsLogged(true)
                  } else {
                      history.push("/admin/login");
                      //setIsLogged(false)
                  }
              } else if (response.status===401 ){
                history.push("/admin/login");
              }
          })
          .catch(function(error) {
          console.log('Hubo un problema con la petición Fetch:' + error.message);
          console.log(error)
          })
      }
  },[history])

    return (
        <div className="profile-page">
            {
                (!(isLogged)) && (
                    <LogoAnimation/>
                )
            }
            <ProfileNavbar/>
            <CustomAlert visible={visible} onDismiss={onDismiss} alertMessage={alertMessage}/>
            <Toast show={show} setShow={setShow} toggle={toastToggle}/>
            <div className="container">
                <div className="row">
                    <div className="col-10">
                        <h1 className="title">Categorías </h1>
                    </div>
                    <div className="col-2">
                        <button className="button" onClick={()=>{setIsShown(!isShown)}}>{isShown? "-" : "+"}</button>
                    </div>
                </div>
                {
                  isShown && (
                    <div className="row">
                        <div className="col-12">
                          <div className="custom-form">
                            <h2>
                              Agregar Nueva Categoría
                            </h2>
                            <CategoryForm categories={prop.categories} item={{}} token={token} setAlertMessage={setAlertMessage} setVisible={setVisible} setChangeProducts={prop.setChangeProducts} changeProducts={prop.changeProducts} setActiveItem={setActiveItem} setIsShown={setIsShown}/>
                          </div>
                        </div>
                    </div>
                  )
                }
                {
                    prop.categories.map((item, index) => {
                        return (
                          <div className="row single-product" key={index}>
                            <div className="col-4">
                              {item.name}
                            </div>
                            <div className="col-4">
                              Código: {item.code} 
                            </div>
                            <div className="col-2">
                            </div>
                            <div className="col-2">
                              <button className="green-button" onClick={()=>setActiveItem(index)}>M</button>
                              <button className="red-button" onClick={()=>deleteCategory(item._id)}>X</button>
                            </div>
                            { (index===activeItem) && (
                              <div className="col-12">
                                <div className="custom-form">
                                  <h3>
                                      Modificar Categoría
                                  </h3>
                                  <CategoryForm categories={prop.categories} item={item} token={token} setAlertMessage={setAlertMessage} setVisible={setVisible} setChangeProducts={prop.setChangeProducts} changeProducts={prop.changeProducts} setActiveItem={setActiveItem}/>
                                </div>
                              </div>
                            )}
                        </div>                            
                        )
                    })
                }
            </div>
        </div>
    );
}

export default Category;

/*
                            <div className="col-2">
                              imagen
                            </div>
*/