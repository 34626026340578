import React from 'react';
import {CustomNavbar} from '../components/custom-navbar';
import {CustomJumbotron} from '../components/custom-jumbotron';
import "../assets/styles/products.css";
import {CustomFooter} from '../components/custom-footer';
import CardType3 from '../components/card-type-3';
import { useEffect } from 'react';
import { useState } from 'react';
import BottleEx from '../assets/images/product_without_image.png';
import LogoAnimation from '../components/logo-animation';

export const Products =(props) => {
    const [categoryName, setCategoryName] = useState("");
    const [activeProduct, setActiveProduct] = useState({isActive:false});
    const [isShown, setIsShown] =useState(false);
    const [activeProductImage, setActiveProductImage] = useState(BottleEx);
    const [products, setProducts] = useState([]);
    const [isProductsList, setIsProductsList] = useState(false);
    const [forceDisplay, setForceDisplay] = useState(false);
    const [banner, setBanner] = useState(true)

    const activeView={
        home:"",
        products:"linear-gradient(180deg, #60D66A 0%, #2FB63B 100%)",
        about:"",
        contact:""
    };

    let updateActiveProduct = (isShown,item) => {
        setIsShown(isShown);
        if (isShown){
            setActiveProduct(item);
        }
    }

    useEffect(()=>{
        if(isShown){
            if (!(activeProduct.image==='')){
                setActiveProductImage("data:image/"+activeProduct.ext+";base64,"+activeProduct.image);
            } else{
                setActiveProductImage(BottleEx);
            }
        }
    },[activeProduct,isShown])
    useEffect(()=>{
        let category=""
        props.categories.forEach((item) => {
            if(item._id===props.match.params.productId){
                category = item.name;
            }
        });
        if (category ===""){
            setCategoryName("TODOS");
        } else{
            setCategoryName(category);
        }
    },[props.match.params.productId,props.categories])

    useEffect(() => {
        let categoryId = props.match.params.productId
        if (props.all_products){
            const my_url = 'https://server3jgt.herokuapp.com/products/';
            let myProfile = fetch(my_url)
            myProfile.then( async function(response) {
                if(response.ok) {
                    let my_json = await response.json()
                    setProducts(my_json);
                    setIsProductsList(true);
                }
            })
        } else {
            const my_url = 'https://server3jgt.herokuapp.com/categories/'+categoryId+"/products";
            let myProfile = fetch(my_url)
            myProfile.then( async function(response) {
                if(response.ok) {
                    let my_json = await response.json()
                    setProducts(my_json);
                    setIsProductsList(true);
                }
            })
        }
    },[props.match.params.productId,props.all_products])
    useEffect(() => {
        console.log(products);
    },[products])
    useEffect(()=>{
        const timer = setTimeout(()=>{
            setForceDisplay(true);
        },4500);
        return () => clearTimeout(timer);
    },[])
    return (
        <div>
            {
                (!((props.isCategories && isProductsList) || forceDisplay)) && (
                    <LogoAnimation/>
                )
            }
            <div style={ (props.isCategories && isProductsList)? {opacity:1} : {opacity:0}}>
                <CustomJumbotron companyInfo={props.companyInfo}/>
                <CustomNavbar activeView={activeView} categories={props.categories}/>
                <div className="products-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>Categoría <strong>{categoryName}</strong></h1>
                                <hr className="hr_title"/>
                            </div>
                        </div>
                        <div className="row">
                            {
                                isShown && (
                                    <div className="active-product" id="products-init">
                                        <div className="active-cancel" onClick={()=>setIsShown(false)}>x</div>
                                        <div className="active-img">
                                            <img src={activeProductImage} alt="active card cap"/>
                                        </div>
                                        <hr className= "hr_blue"/>
                                        <div className="active-title">{activeProduct.name}</div>
                                        <div className="active-text">{"Descripción: "+activeProduct.description}</div>
                                        <div className="active-text">{activeProduct.size}</div>
                                        <div className="active-text">{"Unidades por lote: "+activeProduct.unities}</div>
                                        <a href={"https://api.whatsapp.com/send?phone="+props.companyInfo.networks.whatsapp.value+"&text=Buenos%20días,%20necesito%20información%20sobre%20el%20producto:%20" + activeProduct.name + ",%20de%20tamaño:%20" + activeProduct.size}  target="_blank" rel="noopener noreferrer">
                                            <button className="active-button">Contáctanos</button>
                                        </a>
                                        <hr className= "hr_orange"/>
                                    </div>
                                )
                            }
                            <div className={isShown ? "products-container passive": "products-container"}>
                                {
                                    products.map((item, index) => {
                                        return (
                                            <CardType3 isActive={isShown} key={"category"+index} item={item} setIsShown={updateActiveProduct} windowSize={props.windowSize}/>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {   banner && (
                            <div className="row">
                                <div className="col-12">
                                    <div className="green-banner" onClick={()=>{setBanner(!banner)}}>
                                        <h2>¡DESCARGA NUESTRA LISTA DE PRECIOS EN FORMATO PDF!</h2>
                                    </div>
                                </div>
                            </div>

                        )}
                        {   !banner && (
                            <div className="row">
                                <div className="col-4">
                                    <div className="green-banner">
                                        <a href="https://server3jgt.herokuapp.com/documents/Catalogo_3JGT_Detal.pdf" target="_blank" rel="noopener noreferrer"><h2>Detal</h2></a>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="green-banner">
                                        <a href="https://server3jgt.herokuapp.com/documents/Catalogo_3JGT_Mayor.pdf" target="_blank" rel="noopener noreferrer"><h2>Mayor</h2></a>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="green-banner">
                                        <a href="https://server3jgt.herokuapp.com/documents/Catalogo_3JGT_Distribuidor.pdf" target="_blank" rel="noopener noreferrer"><h2>Distribuidor</h2></a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <CustomFooter companyInfo={props.companyInfo}/>
            </div>
        </div>
    )
}