import React from 'react';
import '../assets/styles/main-animation2.css';
import { ProductAnimation } from './product-animation';

export const MainAnimation2 =(props) => {

    return (
        <div className="main-animation-2">
            <div className="container-left-2" ref={props.blueContainer}>
                <div className="vertical-container">
                    <h1> QUEREMOS SER PARTE DE TU ÉXITO</h1>
                    <p> Fabricamos y comercializamos productos derivados del plástico necesarios en diversos sectores productivos a nivel nacional.</p>
                    <a  href="/products"><button style={props.isPhone? {top:props.topProperty} : {top:""}}> Ver Productos</button></a> 
                </div>
            </div>
            <ProductAnimation/>
        </div>
    );
}