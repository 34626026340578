import React, {useState, useEffect} from 'react';
import CardType1 from '../components/card-type-1';
import '../assets/styles/category-carousel.css';


const CategoryCarousel = (props) => {
    const [numInter, setNumInter] = useState(4);
    const [numMin, setNumMin] = useState(-1);
    const [isShown, setIsShown] = useState(false);
    const isShownResponsive = (isPhone, isMouse) => {
        if((isPhone) || (isMouse)){
            setIsShown(true)
        } else{
            setIsShown(false)
        }
    }
    const prevImage = () => {
        if (numMin>-1){
            setNumMin(numMin-1);
        }}
    const nextImage = () => {
        if (props.categories.length>(numMin+numInter+1)){
            setNumMin(numMin+1);
        }}
    useEffect(()=>{
        if(props.windowSize.width<768){
            setNumInter(1);
            isShownResponsive(true,false);
        } else if (props.windowSize.width<992){
            setNumInter(2);
        } else{
            setNumInter(4);
        }
    },[props.windowSize])
    return (
        <div className="page">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2> La mejor calidad en</h2>
                        <div className="row" 
                            onMouseEnter={()=> isShownResponsive(false, true)}
                            onMouseLeave={()=> isShownResponsive(false, false)}>
                            {
                                isShown && (
                                    <div className="indicator" onClick={prevImage}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" version="1.1" viewBox="0 0 492 492" fill="white">
                                            <path d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12    C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084    c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864    l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z" fillRule="#ffffff"/>
                                        </svg>
                                    </div>
                                )
                            }
                            {
                                props.categories.map((item, index) => {
                                    if((index<(numMin+numInter+1))&&(index>numMin)){
                                    return (
                                        <CardType1 key={"categoryCard"+index} aroute={"/products/"+item._id} title={item.name} text={item.description} image={item.image} ext={item.ext}/>
                                    )} else{
                                    return("");
                                    }
                                })
                            }
                            {
                                isShown && (
                                    <div className="indicator r-0" onClick={nextImage}>
                                        <svg xmlns="http://www.w3.org/2000/svg"  fill="white" viewBox="0 0 492.004 492.004" className="icon">
                                            <path d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z" fill="#ffffff"/>
                                        </svg>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
};

export default CategoryCarousel;