import React from 'react';
import '../assets/styles/custom-jumbotron.css';
import Logo from '../assets/images/Logo-3JGT.png';
import BackgroundJumbo from '../assets/images/background-jumbo.png';

export const CustomJumbotron =(props) => {
    return (
        <div className="jumbotron jumbotron-container">
            <div className="background-jumbo">
                <img src={BackgroundJumbo} alt=""/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-3">
                        <img src={Logo} alt="3JGT" className="jumbotron-img"/>
                    </div>
                    <div className="col-6">
                        <h1>3JGT</h1>
                    </div>
                    <div className="vl"></div>
                    <div className="col-2">
                        <div className="ws-icon">
                            <a href={"https://api.whatsapp.com/send?phone="+props.companyInfo.networks.whatsapp.value} target="_blank" rel="noopener noreferrer">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/240px-WhatsApp.svg.png" alt="WhatsApp"/> 
                                <div className="ws-icon-text">{props.companyInfo.networks.whatsapp.name}</div>   
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}