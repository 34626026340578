import React, { useState, useEffect } from 'react';
import '../assets/styles/instagram_post.css';

const InstagramPost= (props) => {
    
  const [activeClassName, setActiveClassName] = useState("ig-caption");
  const [activeCaption, setActiveCaption] =useState(props.caption);
  useEffect(()=>{
    if ((activeClassName==='ig-caption')){
      if ((props.caption.length)<160){
        setActiveCaption(props.caption)
      } else {
        setActiveCaption(props.caption.slice(0,160-props.caption.length)+"... Ver más")
//        setActiveCaption("hola, ... ver más");
      }
      //setProductImage("data:image/png;base64,"+props.image);
    } else if(activeClassName === "ig-caption-active"){
      if ((props.caption.length)<425){
        setActiveCaption(props.caption)
      } else {
        setActiveCaption(props.caption.slice(0,425-props.caption.length)+"...")
//        setActiveCaption("hola, ... ver más");
      }
    }
  },[props.caption, activeClassName])
  return (
    <div className="col-lg-4 col-md-6 col-12 posts-container">
        <a href={props.permalink} target="_blank" rel="noopener noreferrer">
          <img className="ig-img" src={props.src} alt={props.alt}/>
        </a>
        <div className={activeClassName} onMouseLeave={()=>{setActiveClassName("ig-caption")}} onClick={()=>{setActiveClassName("ig-caption-active")}}>{activeCaption}</div>
    </div>
  );
};

export default InstagramPost;