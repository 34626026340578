import React from 'react';
import '../assets/styles/data.css';

export const DisplayData =(props) => {
    return (
        <div className="data-page">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-4">
                        <div className="data-info">
                            <h2>{props.companyInfo.displaydata.time.title}</h2>
                            <p>{props.companyInfo.displaydata.time.desc}</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="data-info">
                            <h2>{props.companyInfo.displaydata.products.title}</h2>
                            <p>{props.companyInfo.displaydata.products.desc}</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="data-info">
                            <h2>{props.companyInfo.displaydata.categories.title}</h2>
                            <p>{props.companyInfo.displaydata.categories.desc}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}