import React, {useState} from 'react';
import { Col, Row, Button, Form, FormGroup, Input } from 'reactstrap';
import {CustomNavbar} from '../components/custom-navbar';
import {CustomJumbotron} from '../components/custom-jumbotron';
import UbicationSign from '../assets/images/ubication_sign.png';
import {CustomFooter} from '../components/custom-footer';
import FacebookIcon from '../assets/images/facebook-icon.png';
import InstagramIcon from '../assets/images/instagram-icon.png';
//import LinkedInIcon from '../assets/images/linkedin-icon.png';
import WhatsappIcon from '../assets/images/whatsapp-icon.png';
import CustomAlert from '../components/custom-alert';
import "../assets/styles/contact.css";

export const Contact =(props) => {
    const activeView={
        home:"",
        products:"",
        about:"",
        contact:"linear-gradient(180deg, #60D66A 0%, #2FB63B 100%)"
    };
    const [email, setEmail] =useState("");
    const [description, setDescription] =useState("");
    const [visible, setVisible] = useState(false);
    const [alertMessage,setAlertMessage]= useState("");
    const onDismiss = () => setVisible(false);
    const sendForm = (e)=>{      
        //Proceso Comun
        e.preventDefault();
        //let json={};
        if (email===undefined){
            console.log("ERROR")
            return
        }
        if (description===undefined){
            console.log("ERROR")
            return
        }
        
        //json.mail=email;
        //json.about=description;
        const my_url = 'https://server3jgt.herokuapp.com/send-mail?email=' + email + '&about=' + description;
        
/*
        let my_url='https://server3jgt.herokuapp.com/send-mail';
        let method = 'POST';
        let config ={
            method: method,
            body: JSON.stringify(json)
            }*/
        //let pareo = fetch(my_url, config)
        let pareo = fetch(my_url)
        pareo.then( async function(response) {
            if(response.ok) {
            setAlertMessage("Información Pedida con exito")
            setVisible(true);
            
            } else {
            console.log("No Modificado con exito");
            }
        })
        .catch(function(error) {
            console.log('Hubo un problema con la petición Fetch:' + error.message);
            console.log(error)
        })
    }

    return (
        <div>
            <CustomJumbotron companyInfo={props.companyInfo}/>
            <CustomNavbar activeView={activeView} categories={props.categories}/>
            <CustomAlert visible={visible} onDismiss={onDismiss} alertMessage={alertMessage}/>
            <div className="contact-page ">
                <div className="container">
                    <div className="map-wrapper">
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <iframe className="map" src={props.companyInfo.map.src} aria-hidden="false" title="address"></iframe>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="map-text-wrapper">
                                    <img src={UbicationSign} alt="signo"/>
                                    <h3>Puedes encontrarnos en</h3>
                                    <div className="map-text">Calle Pariata con Monsen Sol, Edif. Friuli </div>
                                    <div className="map-text">Piso PB Local C, Urb. El Marqués</div>
                                    <div className="map-text">Miranda.</div>
                                    <hr/>
                                    <div className="map-text">Zona Postal 1071</div>
                                    <div className="map-text">RIF: J-40439084-7</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blue-title">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>Queremos responder todas tus dudas</h1>  
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pm-0-sm mr-0">
                    <div className="col-12 col-sm-6 order-sm-last pm-0-sm">
                        <div className="custom-box">
                            <div>Escríbenos a través de nuestras redes sociales</div>
                            <div>Nuestro equipo se comunicará contigo inmediatamente</div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 pm-0-sm">
                        <div className="custom-container-contact">
                            <div>
                                <a href={"https://api.whatsapp.com/send?phone="+props.companyInfo.networks.whatsapp.value} target="_blank" rel="noopener noreferrer" className="social-wrapper">
                                    <img src={WhatsappIcon} alt="whatsapp"/>
                                    <div>
                                        {props.companyInfo.networks.whatsapp.name}
                                    </div>
                                </a>
                            </div>
                            <div>
                                <a href={props.companyInfo.networks.instagram.value} className="social-wrapper" target="_blank" rel="noopener noreferrer">
                                    <img src={InstagramIcon} alt="instagram"/>
                                    <div>
                                        {props.companyInfo.networks.instagram.name}
                                    </div>
                                </a>
                            </div>
                            <div>
                                <a href={props.companyInfo.networks.facebook.value} className="social-wrapper" target="_blank" rel="noopener noreferrer">
                                    <img src={FacebookIcon} alt="facebook"/>
                                    <div>
                                        {props.companyInfo.networks.facebook.name}
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
                <Form onSubmit={sendForm}>
            .        <Row form className="m-r-0">
                        <Col md={6}>
                            <div className="custom-box custom-box-l">
                                <div>O envíanos un mensaje contándonos cómo podemos trabajar contigo.</div>
                            </div>
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                                <Input type="email" name="email" id="exampleEmail" placeholder="Tu correo electrónico" value={email} onChange={(e)=>setEmail(e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                                <Input type="textarea" name="description" id="description" placeholder="Cuéntanos cómo podemos ayudarte" value={description} onChange={(e)=>setDescription(e.target.value)}/>
                            </FormGroup>
                            <div className="center">
                                <Button className="green">Enviar</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
            <CustomFooter companyInfo={props.companyInfo}/>
        </div>
    )
}
/*
                            <div>
                                <a href="../" className="social-wrapper" target="_blank" rel="noopener noreferrer">
                                    <img src={LinkedInIcon} alt="linkedin"/>
                                    <div>
                                        distribuidora 3jgt
                                    </div>
                                </a>
                            </div>
                            */