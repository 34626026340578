import React, { useState, useEffect } from 'react';
import {
  Card, CardImg, CardText, 
  CardTitle
} from 'reactstrap';
import BottleEx from '../assets/images/product_without_image.png';
import '../assets/styles/custom_card2.css';

const CardType2= (props) => {
  const [productImage, setProductImage] = useState(BottleEx);
  useEffect(()=>{
    if (!(props.image==='')){
      setProductImage("data:image/"+props.ext+";base64,"+props.image);
    }
  },[props.image, props.ext])
  return (
    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
        <div className="custom-card-2">
          <Card>
            <div className="card_image_box">
              <CardImg top src={productImage} alt="Card image cap"/>
            </div>
            <hr/>
            <CardTitle>{props.title}</CardTitle>
            <CardText className="card-2-description">{props.text}</CardText>
            <a href={"https://api.whatsapp.com/send?phone="+props.companyInfo.networks.whatsapp.value+"&text=Buenos%20días,%20necesito%20información%20sobre%20el%20producto:%20" + props.title + ",%20de%20tamaño:%20" + props.size} target="_blank" rel="noopener noreferrer"><CardText className="card-2-more">Contáctanos</CardText></a>
          </Card>
        </div>
    </div>
  );
};

export default CardType2;