import React, {useState} from 'react';
import '../assets/styles/custom-navbar.css'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarBrand
  } from 'reactstrap';
import Logo from '../assets/images/Logo-3JGT.png';
import Hamburger from '../assets/images/Hamburguer.png';

export const CustomNavbar =(props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
      <div>
        <Navbar expand="md" className="custom-navbar">
            <NavbarBrand href="../" className="custom-nv-title">3JGT</NavbarBrand>
            <NavbarToggler onClick={toggle} >
              <img src={Hamburger} alt=""/>
            </NavbarToggler>
            <Collapse isOpen={isOpen} navbar >
            <Nav navbar className="nav-wrapper">
              <NavItem className="sm-brand">
                <NavLink href="../">
                  <img src={Logo} alt="Logo"/>
                </NavLink>
              </NavItem>
              <NavItem className="nav-item" style={{background:props.activeView.home}}>
                <NavLink href="../" className="nav-item-text">Inicio</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar className="nav-item">
                <DropdownToggle nav caret className="nav-item-text" style={{background:props.activeView.products}}>
                  Productos
                </DropdownToggle>
                <DropdownMenu className="custom-dropdown" style={{background:props.activeView.products}}>
                  {
                    props.categories.map((item, index) => {
                        return (
                          <DropdownItem key={"category"+index} className={(props.activeView.products==="")? "custom-dropdown-hover": "custom-dropdown-hover-orange"}>
                            <NavLink href={"/products/"+item._id} className="nav-item-text">{item.name}</NavLink>
                          </DropdownItem>
                        )
                    })
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem className="nav-item" style={{background:props.activeView.about}}>
                <NavLink href="/about" className="nav-item-text">Sobre nosotros</NavLink>
              </NavItem>
              <NavItem className="nav-item nav-item-orange" style={{background:props.activeView.contact}}>
                <NavLink href="/contactus" className="nav-item-text">Contáctanos</NavLink>
              </NavItem>
              <NavItem className="sm-brand-ws">
                <NavLink href="https://api.whatsapp.com/send?phone=+584242196205">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/240px-WhatsApp.svg.png" alt="WhatsApp"/> 
                  <div className="ws-icon-text">+58 4242196205</div>   
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
}