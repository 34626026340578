import React from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';

const CustomToast = (props) => {
  return (
    <div>
      <br />
      <br />
      <div className="p-3 my-2 rounded bg-docs-transparent-grid">
        <Toast isOpen={props.show}>
          <ToastHeader toggle={props.toggle}>Toast title</ToastHeader>
          <ToastBody>
            Lorem ipsum dolor sit amet
          </ToastBody>
        </Toast>
      </div>
    </div>
  );
}

export default CustomToast;
//